import React, { useEffect, useState, forwardRef, useRef, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers, BigNumber } from "ethers";

import {
  USD_DECIMALS,
  formatAmount,
  getTokenInfo,
  useLocalStorageByChainId,
  useChainId,
  getPageTitle,
  CALL,
  expandDecimals,
  PUT,
} from "../../lib/legacyOptions";
import { useInfoTokens } from "../../../gmx-test/domain/legacy";

import { getContract } from "../../config/Addresses";
import { getTokens, getToken, getTokenBySymbol } from "../../config/Tokens";

import Reader from "../../abis/ReaderV2.json";

import SwapBox from "../../components/Exchange/SwapBoxOptions";
import ExchangeTVChart from "../../components/Exchange/ExchangeTVChartOptions";
import PositionsList from "../../components/Exchange/PositionsListOptions";
import Tab from "../../components/Tab/TabOptions";

import "../../../gmx-test/pages/Exchange/Exchange.scss";
import "./ExchangeOptions.scss";
import "./ExchangeLists.scss";
import { fetcher } from "../../lib/contracts/fetcher";
import { ARBITRUM } from './../../lib/legacyOptions';
import OptionsList from './../../components/Exchange/OptionsList';
import { CHAI_CONTRACT_BTC, CHAI_CONTRACT_ETH,
  DDL_TP_Hegic_ABI, 
  DDL_TP_Hegic_addr,
  DDL_TP_Lyra_ETH_addr,
  DDL_TP_Lyra_BTC_addr,
  DDL_TP_Lyra_ABI,
  HEGIC_SENTIMENT_LABELS, HEGOPS, HEGOPS_ABI, HegicStrategy_ABI, OperationalTreasury, OperationalTreasury_ABI, getTPContract, getTPApproveContract, Chai_ABI } from './../../../../components/utils/contracts';
import { errAlert } from "../../../../components/utils/notifications";
import { DDL_WALLET, OPTIONS_DECIMALS, tokenDecimals } from "../../../../components/utils/constants";
import { DDL_WALLET_ENCODED, OPTION_TYPES } from './../../../../components/utils/constants';
import { HEGIC_STRATEGIES } from "./../../../../components/utils/contracts";
import { getOptionProfitZone } from "../../../../components/utils/utils";
import { alchemyProvider, getLyra } from './../../../../components/utils/providers';
import MarkPricePanel from './../../../../components/MarkPricePanel';
import useGodEye from "../../../../hooks/useGodEye";


const { AddressZero } = ethers.constants;

export const Exchange = (props) => {
  const { account, active, library } = useGodEye();

  const { chainId } = useChainId();
  const defaultTokenSelection = AddressZero;

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-options",
    defaultTokenSelection
  );
  
  // Info Tokens + Balances
  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);
  const readerAddress = getContract(chainId, "Reader");
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: fetcher(library, Reader, [tokenAddresses]),
    refreshInterval: 10 * 1000
  });
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances);
  
  const selectedToken = getTokenInfo(infoTokens, tokenSelection);

  const chaiAddress = selectedToken.symbol === 'ETH'
    ? CHAI_CONTRACT_ETH : CHAI_CONTRACT_BTC;
  const { data: chaiMarkPrice } = useSWR(
    active && [active, tokenSelection, "getMarkPrice_Options", account],
    {
      fetcher: async () => {
        const contract = new ethers.Contract(chaiAddress, Chai_ABI, library.getSigner());
        return await contract.latestAnswer();
      },
      refreshInterval: 5000,
    }
  )

  useEffect(() => {
    const toToken = getTokenInfo(infoTokens, tokenSelection);
    // let currentTokenPriceStr = formatAmount(toToken.maxPrice, USD_DECIMALS, 2, true);
    // let title = getPageTitle(currentTokenPriceStr + ` | ${toToken.symbol}${toToken.isStable ? "" : "USD"}`);
    document.title = "My Positions | Sharwa.Finance";
  }, [tokenSelection, infoTokens, chainId]);

  const renderChart = () => {
    return (
      <ExchangeTVChart
        tokenSelection={tokenSelection}
        setTokenSelection={setTokenSelection}
        infoTokens={infoTokens}
        chainId={chainId}
        savedShouldShowPositionLines={false}
      />
    );
  };
  
  const [leverageOption, setLeverageOption] = useLocalStorageByChainId(ARBITRUM, "Period_Options", 7);
  const [amountValue, setAmountValue] = useState('');
  const [swapOption, setSwapOption] = useLocalStorageByChainId(ARBITRUM, "Swap-option_Options", CALL);
  const [assetOption, setAssetOption] = useLocalStorageByChainId(ARBITRUM, "Asset-option", "ETH");

  const setMarket = (swap, token) => {
    setSwapOption(swap);
    setTokenSelection(getTokenBySymbol(ARBITRUM, token).address);
  }

  useEffect(() => {
    setAssetOption(selectedToken.symbol);
  }, [selectedToken.symbol]);

  const [options, setOptions] = useState([]);
  const [optionsLength, setOptionsLength] = useState(0);
  const [optionsExpectedLength, setOptionsExpectedLength] = useState(0);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [optionPrices, setOptionPrices] = useState({});

  const scrollRef = useRef(null);
  const scrollToList = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  // Fetching positions
  const [positions, setPositions] = useState([]);
  const [pendingPositions, setPendingPositions] = useState([]);
  useEffect(() => {
    setPositions([]);
  }, [account]);
  const fetchHegicPositions = async () => {
    let strategiesAddresses = [];
    const provider = alchemyProvider;
    const signer = library.getSigner();
    // chaiMarkPrice 1e18 / 1e8 -- ETH / BTC
    const chaiContractETH = new ethers.Contract(CHAI_CONTRACT_ETH, Chai_ABI, signer);
    const chaiContractBTC = new ethers.Contract(CHAI_CONTRACT_BTC, Chai_ABI, signer);
    const chaiMarkPrices = {
      ETH: await chaiContractETH.latestAnswer(),
      BTC: await chaiContractBTC.latestAnswer()
    };

    Object.values(HEGIC_STRATEGIES).forEach(obj => {
      strategiesAddresses = [...strategiesAddresses, ...Object.values(obj)];
    })

    const contract = new ethers.Contract(HEGOPS, HEGOPS_ABI, provider);
    const transferFilter = {
      address: contract.address,
      topics: [
        ethers.utils.id("Transfer(address,address,uint256)"),
        null,
        ethers.utils.hexZeroPad(account, 32),
      ]
    };
    const events = await contract.queryFilter(transferFilter);
    if (!events.length) {
      return;
    }
    const tokenIds = events.map(e => {
      return e.args.tokenId;
    })
    
    const buyFilterTopics = [
      ethers.utils.id("Acquired(uint256,(uint128,uint128),uint256,uint256,uint256,bytes[])"),
      tokenIds.map(id => {
        return ethers.utils.hexZeroPad(id, 32);
      }),
    ]

    const eventsPack = [];
    let eventsPackLimit = 13;
    const lastPackLength = strategiesAddresses.length % eventsPackLimit;
    for (let i = 0; i < strategiesAddresses.length; i++) {
      const address = strategiesAddresses[i];
      const contract = new ethers.Contract(address, HegicStrategy_ABI, provider);

      const buyFilter = {
        address,
        topics: buyFilterTopics,
      }
      const events = contract.queryFilter(buyFilter)
        .then(eArr => {
          eArr.forEach(e => {
            formatPosition(e);
          });
        })
      eventsPack.push(events);

      if (i + 1 > (strategiesAddresses.length - lastPackLength)) {
        eventsPackLimit = lastPackLength;
      }
      if (eventsPack.length >= eventsPackLimit) {
        await Promise.all(eventsPack)
          .then(() => {
            // setPositions([...positions]);
          });
        eventsPack.splice(0, eventsPack.length);
      }
    }
    async function formatPosition(e) {
      const data = e.decode(e.data, e.topics);
      const OpTreasury = new ethers.Contract(OperationalTreasury, OperationalTreasury_ABI, provider);
      const additionalData = (await OpTreasury.lockedLiquidity(data.id));
      const isActive = additionalData.state;
      if (!isActive) {
        return;
      }

      const periodDays = data.period.toString() / 60**2 / 24;
      let strategies;
      if (periodDays < 14) {
        strategies = HEGIC_STRATEGIES.short;
      } else if (periodDays < 30) {
        strategies = HEGIC_STRATEGIES.medShort;
      } else if (periodDays < 60) {
        strategies = HEGIC_STRATEGIES.medLong;
      } else if (periodDays <= 90) {
        strategies = HEGIC_STRATEGIES.long;
      } else {
        return;
      }

      const strategy = Object.keys(strategies).find(key => strategies[key] === e.address);
      const [, sentiment, strikeScale, token] = strategy.split('_');
      const isCall = sentiment.split('-')[0] === 'CALL';
      const isSpread = sentiment.split('-').length > 1;
      // size 1e18 / 1e8 -- ETH / BTC
      const size = expandDecimals(data.data.amount, OPTIONS_DECIMALS - tokenDecimals[token]);
      // chaiMarkPrice 1e18 / 1e8 -- ETH / BTC
      const chaiMarkPrice = expandDecimals(chaiMarkPrices[token], OPTIONS_DECIMALS - tokenDecimals[token]);
      // strike 1e8
      const buyingPrice = expandDecimals(data.data.strike, OPTIONS_DECIMALS - 8);
      const comparisonMethod = isCall ? "gt" : "lt";
      const isITM = buyingPrice[comparisonMethod](chaiMarkPrice);
      let strikePrice = isITM ? buyingPrice : buyingPrice.div(100).mul(strikeScale);
      if (isSpread) {
        strikePrice = buyingPrice;
      }
      // premium 1e6
      const premium = expandDecimals(data.positivepnl, OPTIONS_DECIMALS - 6);
      const stratContract = new ethers.Contract(additionalData.strategy, HegicStrategy_ABI, provider);
      // profit 1e6
      const profit = expandDecimals(await stratContract.payOffAmount(data.id), OPTIONS_DECIMALS - 6);
      const pnl = {
        unrealizedPnl: profit.sub(premium),
        unrealizedPnlPercentage: expandDecimals(expandDecimals(profit.sub(premium), 4).div(premium), OPTIONS_DECIMALS - 4)
      };
      // Profit Zone
      const { profitZone, profitZoneStr } = getOptionProfitZone(isCall, strikePrice, premium, size);
      // SL/TP
      const DDL_TakeProfit = getTPContract("Hegic", token, signer);
      const tpInfo = await DDL_TakeProfit.tokenIdToTokenInfo(data.id);
      let tp, sl;
      if (!tpInfo.takeProfitPrice.eq(0)) {
        // price 1e8
        const price = expandDecimals(tpInfo.takeProfitPrice, OPTIONS_DECIMALS - 8);
        if (tpInfo.takeType === 0) {
          if (isCall) {
            tp = price;
          } else {
            sl = price;
          }
        } else {
          if (isCall) {
            sl = price;
          } else {
            tp = price;
          }
        }
      }

      const expiry = additionalData.expiration * 1000;
      const approveContract = getTPApproveContract("Hegic", token, signer);

      // Two children with the same key fix
      const uniqStrategy = strategy + "_" + premium.toString();
      const fetchedPosition = {
        strategy: uniqStrategy,
        market: "Hegic",
        type: OPTION_TYPES.Hegic,
        id: data.id,
        isCall,
        sentiment: HEGIC_SENTIMENT_LABELS[sentiment],
        token,
        size,
        strikePrice,
        premium,
        expiry,
        pnl,
        profitZone,
        profitZoneStr,
        isClosingDisabled: profit.eq(0),
        tp,
        sl,
        close: () => {
          return OpTreasury.connect(signer).payOff(data.id, account);
        },
        checkIsTpApproved: () => {
          return approveContract.isApprovedOrOwner(DDL_TakeProfit.address, data.id);
        },
        approveTp: () => {
          return approveContract.approve(DDL_TakeProfit.address, data.id);
        }
      }
      const index = positions.findIndex(el => el.strategy === uniqStrategy);
      if (index === -1) {
        positions.push(fetchedPosition);
        // Remove buying positions
        setPendingPositions(pendingPositions.filter(pos => typeof pos !== 'object'));
      } else {
        positions[index] = fetchedPosition;
      }
    }
  }
  const fetchLyraPositions = async () => {
    const LYRA = getLyra();
    const posArr = await LYRA.positions(account);
    const SLIPPAGE = 0.015;

    posArr.forEach(async pos => {
      const { id, strikeId, strikePrice, isCall, isLong, pricePerOption, size, expiryTimestamp  } = pos;
      if (size.eq(0) || expiryTimestamp * 1000 < Date.now()) {
        return;
      }

      const isBuy = isLong;
      if (!isBuy) {
        return;
      }
      
      let token = pos.marketName.split('-')[0];
      if (token.startsWith('W')) {
        token = token.substring(1, token.length);
      }

      // SL/TP
      let tp, sl;
      const DDL_TakeProfit = getTPContract("Lyra", token, library.getSigner());
      const tpInfo = await DDL_TakeProfit.tokenIdToTokenInfo(id);
      if (!tpInfo.stopOrderPrice.eq(0)) {
        // price 1e8
        const price = expandDecimals(tpInfo.stopOrderPrice, OPTIONS_DECIMALS - 8);
        if (tpInfo.stopOrderType === 0) {
          if (isCall) {
            tp = price;
          } else {
            sl = price;
          }
        } else {
          if (isCall) {
            sl = price;
          } else {
            tp = price;
          }
        }
      }

      const expiry = expiryTimestamp * 1000;
      
      const trades = pos.trades();
      const premium = trades.reduce((premium, trade) => {
        const method = trade.isBuy ? "add" : "sub";
        return premium[method](trade.premium);
      }, BigNumber.from(0));
      // Hide selled options
      if (premium.lt(0)) {
        return;
      }
      
      const strategy = `Lyra_${isCall ? "CALL" : "PUT"}_${strikeId.toString()}_${token}_${premium.toString()}`;
      const pnl = pos.pnl();
      const { profitZone, profitZoneStr } = getOptionProfitZone(isCall, strikePrice, premium, size);

      // IncorrectOwner error fix
      pos.owner = account;

      const approveContract = getTPApproveContract("Lyra", token, library.getSigner());
      const fetchedPosition = {
        strategy,
        market: "Lyra",
        type: OPTION_TYPES.Lyra,
        isCall,
        id,
        sentiment: isCall ? CALL : PUT,
        isBuy,
        token,
        size,
        strikePrice,
        pricePerOption,
        premium,
        expiry,
        pnl,
        profitZone,
        profitZoneStr,
        tp,
        sl,
        getTrade: async () => {
          return await pos.trade(/* isBuy */ false, size, SLIPPAGE);
        },
        close: async (size) => {
          const signer = library.getSigner();
          const trade = await pos.close(size, SLIPPAGE, { referrer: DDL_WALLET });
          // add referrer
          trade.tx.data = trade.tx.data.substring(0, trade.tx.data.length - DDL_WALLET_ENCODED.length);
          trade.tx.data+= DDL_WALLET_ENCODED;
          // visually
          trade.params[0].referrer = DDL_WALLET;

          if (trade.isDisabled) {
            errAlert(new Error(trade.disabledReason));
          }
          
          return signer.sendTransaction(trade.tx);
        },
        checkIsTpApproved: () => {
          return approveContract.ownerOf(id)
            .then(res => {
              return res.toLowerCase() === DDL_TakeProfit.address.toLowerCase();
            });
        },
        approveTp: () => {
          return approveContract.approve(DDL_TakeProfit.address, id);
        }
      };
      const index = positions.findIndex(el => el.strategy === strategy);
      if (index === -1) {
        positions.push(fetchedPosition);
        // Remove buying positions
        setPendingPositions(pendingPositions.filter(pos => typeof pos !== 'object'));
      } else {
        positions[index] = fetchedPosition;
      }
      
      return positions;
    });
  }
  const { data: positionsData, error: positionsDataError } = useSWR(
    active && [active, account, library, chainId, "getPositions_Options", pendingPositions],
    {
      fetcher: async () => {
        // Remove closing positions
        if (pendingPositions.length) {
          pendingPositions.forEach((strat, i) => {
            if (typeof strat !== 'string') {
              return;
            }
            const pendingIndex = positions.findIndex(pos => pos.strategy === strat);
            if (pendingIndex > -1) {
              positions.splice(pendingIndex, 1);
              setPositions([...positions]);
  
              if (i + 1 === pendingPositions.length) {
                setPendingPositions(pendingPositions.filter(pos => typeof pos !== 'string'));
              }
            }
          })
        }
        // End remove closing positions
        await Promise.all([
          fetchHegicPositions(),
          fetchLyraPositions(),
        ]);

        return true;
      },
    }
  );
  if (positionsDataError) {
    console.log("Fetching Positions error!", positionsDataError);
  }
  // End Fetching positions

  // Fetching Commission data, no changes expected
  const { data: SLTPCommission, error: SLTPCommissionError } = useSWR(
    active && library && [active, library, "getSLTPCommission_Options", account],
    {
      fetcher: async () => {
        const DDL_TakeProfit = getTPContract("Hegic", "", library.getSigner());
        const commission = await DDL_TakeProfit.commissionSize();
        return commission;
      },
      refreshInterval: 300 * 1000,
    }
  )
  if (SLTPCommissionError) {
    console.log("DDL_TakeProfit commission error!", SLTPCommissionError);
  }
  // End Commission data

  // Sections Labels
  const LIST_SECTIONS = ["Positions"];
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    Positions: "Active",
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  const positionsDataIsLoading = active && !positionsData && !positionsDataError;
  // End Sections Labels
  
  const getListSection = () => {
    return (
      <>
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="Exchange-list-tip">
            *The table displays estimated PnL values. To view the actual PnL, click on the «Close» button
          </div>
        </div>
        {listSection === "Positions" && (
          <PositionsList
            infoTokens={infoTokens}
            active={active}
            account={account}
            library={library}
            setMarket={setMarket}
            positions={positions}
            positionsLoading={positionsDataIsLoading}
            positionsError={positionsDataError}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            SLTPCommission={SLTPCommission}
          />
        )}
      </>
    );
  };

  if (!getToken(chainId, tokenSelection)) {
    return null;
  }
  return (
    <div className="Exchange page-layout">
      <div className="Exchange-content">
        {/* <div className="Exchange-left">
          {renderChart()}
        </div>
        <div className="Exchange-right">
          <SwapBox
            chainId={chainId}
            infoTokens={infoTokens}
            active={active}
            connectWallet={connectWallet}
            account={account}
            library={library}
            account={account}
            selectedToken={selectedToken}
            tokenSelection={tokenSelection}
            setTokenSelection={setTokenSelection}
            leverageOption={leverageOption}
            setLeverageOption={setLeverageOption}
            amountValue={amountValue}
            setAmountValue={setAmountValue}
            swapOption={swapOption}
            setSwapOption={setSwapOption}
            assetOption={assetOption}
            setAssetOption={setAssetOption}
            options={options}
            setOptions={setOptions}
            setOptionsLength={setOptionsLength}
            setOptionsExpectedLength={setOptionsExpectedLength}
            isOptionsLoading={isOptionsLoading}
            setIsOptionsLoading={setIsOptionsLoading}
            optionPrices={optionPrices}
            setOptionPrices={setOptionPrices}
            scrollToList={scrollToList}
            chaiMarkPrice={chaiMarkPrice}
          />
        </div> */}
        <div className="Exchange-lists-container">
          <div className="Exchange-lists-header">
            <h1 className="Exchange-lists__title">
              My positions
            </h1>
            <MarkPricePanel
              infoTokens={infoTokens}
            />
          </div>
          <div className="Exchange-lists large">{getListSection()}</div>
          <div className="Exchange-lists small">{getListSection()}</div>
        </div>
        {/* <OptionsList
          active={active}
          library={library}
          options={options}
          setOptions={setOptions}
          optionsExpectedLength={optionsExpectedLength}
          optionPrices={optionPrices}
          isLoading={isOptionsLoading}
          scrollRef={scrollRef}
          swapOption={swapOption}
          account={account}
          infoTokens={infoTokens}
          positions={positions}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
        /> */}
      </div>
    </div>
  );
};
